import { Wrap, Text, Avatar, Box, Button, Flex, FormControl, FormLabel, Heading, Input, InputGroup, InputLeftAddon, InputRightAddon, Select, Textarea, useToast} from "@chakra-ui/react";
import SidebarSuperAdmin from "../../components/SidebarSuperAdmin";
import { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import api from "../../services/api";
import Select2 from 'react-select';
// import {NumberFormat} from "react-number-format";

const AddDataSaling = () => {

    const toast = useToast();

    const [custName, setCustName] = useState([]); // state to save name after search name customers 
    const [fishName, setFishName] = useState([]); // state to save name after search name fish
    const [custNameSelect, setCustNameSelect] = useState(0); //state to save customer name after select
    const [fishNameSelect, setFishNameSelect] = useState(0); //state to save fish name after select option 
    const [price, setPrice] = useState({}); //state to save price after getting id fish and id customers
    const [totalFish, setTotalFish] = useState(0); //state to save value total fish in kg  
    const [totalPrice, setTotalPrice] = useState(0); //state to save value total price 
    const [status, setStatus] = useState(""); //state to save status paid
    const [paymentRemaining, setPaymentRemaining] = useState(0); // state to save payment remaining
    const [notes, setNotes] = useState(""); // state to save value notes
    const [users, setUsers] = useState(0); //state to save id users 
    const [profile, setProfile] = useState({}); // set profile users 
    const [loadingAdd, setLoadingAdd] = useState(false); //set loading add data

    // get price by customer id and fish id 
    const getPrice = async() => {
        try {
            if (custNameSelect !== 0 || fishNameSelect !== 0) {
                const response = await api.get(`/api/v5/price/cust/${custNameSelect}/fish/${fishNameSelect}`)
                setPrice(response.data.payload[0])
            }
        } catch (error) {
            console.log("Error Fetching data", error);
            if (error.response.data.status_code !== 200) {
                    toast({
                        title: 'Error...!',
                        description: `Add Price For Customer First.!`,
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                        position: "top",
                    });
            }
        }
    };

    // debounce get total product 
    const handleGetTotalFish = useCallback(
        debounce(async (e) => {
            const total = e.target.value;

            if (total !== "") {
                setTotalFish(total);
            }
        }, 100), [],
    );

    // function to get total price 
    const handleTotalPrice = () => {
        if (totalFish !== 0 || price.price !== 0) {
            const total = totalFish * price.price;
            setTotalPrice(total);
        }else{
            toast({
                title: 'Error...!',
                description: `Fish And Price Must be Valid`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: "top",
            });
        }
    };

    // handle status choice
    const handleStatusPaid = (e) => {
        const status = e.target.value;

        if (status !== "") {
            setStatus(status);
        };

        // cek status jika not yet paid 
        if (status === "Not Yet Paid") {
            setPaymentRemaining(totalPrice);
        };
    };

    console.log(paymentRemaining)

    // handle get payment remaining 
    const handlePaymentRemaining = useCallback(
        debounce((e) => {
            const paymentRemaining = e.target.value;

            if (paymentRemaining !== 0) {
                setPaymentRemaining(paymentRemaining)
            }
        }, 200), [],
    );
    // console.log(totalPrice)

    // handle notes input 
    const handleGetNotes = useCallback(
        debounce((e) => {
            const notes = e.target.value;

            if (notes !== "") {
                setNotes(notes)
            }
        }, 200), [],
    );

    // handle change select option customer 
    const handleSelectCust = (e) => {
        if (e !== null) {
            setCustNameSelect(e.value);
            // console.log(e.value)
        }
    };

    // handle change select option fish 
    const handleSelectFish = (e) => {
        if (e !== null) {
            setFishNameSelect(e.value);
            // console.log(e.value)
        }
    };

    // get users by token 
    const getUsersByToken = async() => {
        // get token from localstorage
        const token = localStorage.getItem("access_token");

        try {
            if (token !== null) {
                const response = await api.get(`/api/v1/users/user/profile/${token}`);
                if (response.length !== 0) {
                    setUsers(response.data.payload.id);
                    // console.log(response.data.payload.id)
                }
            }
        } catch (error) {
            console.log("Error Fetching data", error);
            if (error.response.data.status_code !== 200) {
                    toast({
                        title: 'Error...!',
                        description: `${error.response.data.message}`,
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                        position: "top",
                    });
            }
        }
    };

    // add data recap
    const addDataRecap = async() => {

        setLoadingAdd(true);

        const addNewRecap = {
            customerId: parseInt(custNameSelect),
            fishId: parseInt(fishNameSelect),
            usersId: users,
            priceByCustomersId: price.id,
            price: price.price,
            total_product: parseInt(totalFish),
            total_price: totalPrice,
            status: status,
            remainingAmount: parseInt(paymentRemaining),
            notes: notes,
        };

    
        // tidak dicek semua harus ada karena pada form sudah terdapat properti is required

        try {
            const response = await api.post(`/api/v6/recap`, addNewRecap);
            if (response.data.status_code === 200) {
                toast({
                    title: 'Data created.',
                    description: `${response.data.message}`,
                    status: 'success',
                    duration: 1500,
                    isClosable: true,
                    position: "top",
                })
            }

            setTimeout(() => {
               window.location.reload(); 
            }, 1500);
        } catch (error) {
            console.log("Error Fetching data", error);
            if (error.response.data.status_code !== 200) {
                toast({
                    title: 'Error',
                    description: `${error.response.data.message}`,
                    status: 'error',
                    duration: 1500,
                    isClosable: true,
                    position: "top",
                });
            }
        } finally{
            setLoadingAdd(false);
        }
    };

    // get data user by token 
    const getUsers = async() => {
        const token = localStorage.getItem('access_token');
        // console.log(token)
        try {
            if (token !== null) {
                const response = await api.get(`/api/v1/users/user/profile/${token}`);
                if (response.data.status_code === 200) {
                    setProfile(response.data.payload);
                }
                // console.log(response.data.payload)
            }
        } catch (error) {
            console.log("Error Fetching Data", error);
            toast({
                title: 'Error.!',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            }); 
        }
    }

    // get all customers only name and id 
    const getAllCustomers = async() => {
        try {
            const response = await api.get('/api/v4/cust/customer/name');
            setCustName(response.data.payload);
        } catch (error) {
            console.log("Error Fetching Data", error);
            toast({
                title: 'Error.!',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            }); 
        }
    }

    // get all fish onyl name and id 
    const getAllFish = async() => {
        try {
            const response = await api.get('/api/v3/fish/fishes/name');
            setFishName(response.data.payload);
        } catch (error) {
            console.log("Error Fetching Data", error);
            toast({
                title: 'Error.!',
                description: `${error.response.data.message}`,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            }); 
        }
    }

    useEffect(() => {
        getUsersByToken();
        getUsers();
        getAllCustomers();
        getAllFish();
    },[]);

    return(
        <>
            <Box bgColor={"#F5F7FA"} bgSize={"cover"} height={"100vh"} display={"flex"}>
                <SidebarSuperAdmin/>

                <Box margin={"50px"} width={"7xl"} display={"flex"} flexDirection={"column"} marginLeft={"280px"}>

                    <Box marginLeft={"10px"} marginTop={"20px"} display={"flex"} flexDir={"row"}>
                        <Box> 
                            <Heading>Add Data Selling</Heading>    
                        </Box>
                        
                        <Box marginLeft={"auto"}>
                            <Wrap spacing={"15px"}>
                                <Text fontWeight={"semibold"} fontSize={"xl"} marginTop={"9px"}>{profile?.full_name ? profile.full_name.split(' ').slice(0, 2).join(' ') : ''}</Text>
                                <Avatar name={profile?.full_name ?? ''}></Avatar>
                            </Wrap>
                        </Box>
                    </Box>
                    
                    <Box padding={"30px"} bgColor={"#FFFFFF"} borderRadius={"30px"} marginTop={"30px"} minHeight={"77vh"}>
                        <form>
                            <FormControl isRequired width={"100%"}>
                                <Flex flexDir={"row"} gap={10}>
                                    <Box width={"40%"}>
                                        <FormLabel>Select Customer</FormLabel>

                                        <Select2 
                                        placeholder="Select Name Customer" name="customer" 
                                        options={custName.map(customer => ({label: customer.name, value: customer.id}))} 
                                        isSearchable={true} isClearable={true} onChange={handleSelectCust} classNamePrefix={"custom-select"} 
                                        styles={{control: (provided) => ({
                                            ...provided,
                                            marginBottom: "20px", borderRadius: "6px", height: "40px" 
                                        })}}
                                        >
                                        </Select2>
                                    
                                        <FormLabel>Select Fish</FormLabel>

                                        <Select2 
                                        placeholder="Select Name Fish" name="fish" 
                                        options={fishName.map(fish => ({label: fish.name, value: fish.id}))} 
                                        isSearchable={true} isClearable={true} onChange={handleSelectFish} classNamePrefix={"custom-select"} 
                                        styles={{control: (provided) => ({
                                            ...provided,
                                            marginBottom: "20px", borderRadius: "6px", height: "40px" 
                                        })}}
                                        >
                                        </Select2>

                                        <FormLabel>Price</FormLabel>
                                        <InputGroup marginBottom={"20px"}>
                                            <InputLeftAddon>
                                                <Button onClick={() => {getPrice()}}>Get Price</Button>
                                            </InputLeftAddon>
                                            <Input placeholder="Price" type="text" borderEndRadius={"7px"} defaultValue={price.price ? "Rp." + price.price.toLocaleString("id-ID") : ""}></Input>
                                        </InputGroup>

                                        <FormLabel>Total Fish 'Kg'</FormLabel>
                                        <InputGroup>
                                            <Input placeholder="Total Kg" type="Number" marginBottom={"20px"} onChange={handleGetTotalFish}></Input>
                                            <InputRightAddon>
                                                Kg
                                            </InputRightAddon>
                                        </InputGroup>

                                        <FormLabel>Total Price</FormLabel>
                                        <InputGroup size={"md"}>
                                                <InputLeftAddon>
                                                    <Button onClick={handleTotalPrice} size={"sm"} >Get Total Price</Button>
                                                </InputLeftAddon>
                                                <Input placeholder="Rp." type="text" marginBottom={"20px"} defaultValue={totalPrice ? "Rp." + totalPrice.toLocaleString("id-ID") : ""} readOnly ></Input>
                                        </InputGroup>   
                                    </Box>

                                    <Box width={"40%"}>
                                        <FormLabel>Status</FormLabel>
                                        <Select placeholder="Status Paid" marginBottom={"20px"} onChange={handleStatusPaid}>
                                            <option>Paid</option>
                                            <option>Not Yet Paid</option>
                                            <option>Not Yet Paid Off</option>
                                        </Select>

                                        <FormLabel>Payment Remaining (Kekurangan)</FormLabel>
                                        <InputGroup>
                                            <InputLeftAddon>Rp.</InputLeftAddon>
                                            <Input placeholder="Input - Price" marginBottom={"20px"} onChange={handlePaymentRemaining} value = {status === "Not Yet Paid" ? paymentRemaining : undefined}></Input>
                                        </InputGroup>

                                        <FormLabel>Notes</FormLabel>
                                        <Textarea placeholder="Notes" marginBottom={"20px"} onChange={handleGetNotes} defaultValue={""}></Textarea>

                                        <Button onClick={addDataRecap} isLoading={loadingAdd} loadingText="Adding..." colorScheme="green" marginTop={"84px"}>Add</Button>
                                    </Box>
                                </Flex>               
                            </FormControl>
                        </form>
                    </Box>
                </Box>
            </Box>
        </>
    )
};

export default AddDataSaling;